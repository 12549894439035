<template>
  <div class="drugsDetail" v-loading="loading">
    <!-- 顶部面包屑 -->
    <div class="top_breadcrumb" v-if="detail">
      <span @click="$router.push('/drugs?type=' + $route.query.type)">
        <i class="el-icon-arrow-left"></i>{{ detail.info.drug_type1 }}
      </span>
      <span @click="goBack1">
        <i class="el-icon-arrow-left"></i>{{ detail.info.drug_type2 }}
      </span>
      <span>
        <i class="el-icon-arrow-left"></i>{{ detail.info.drug_type3 }}
      </span>
    </div>

    <!-- banner -->
    <el-carousel
      v-if="bannerArr.length"
      :autoplay="false"
      arrow="always"
      height="260px"
    >
      <el-carousel-item v-for="(item, index) in bannerArr" :key="index">
        <el-image :src="item" alt="" :preview-src-list="[item]"></el-image>
      </el-carousel-item>
    </el-carousel>

    <!-- 主要内容 -->
    <div class="content" v-if="detail">
      <div class="banner" v-if="!bannerArr.length">
        <img src="@image/drugsBanner.png" alt="" />

        <div class="word">
          <h4>{{ detail.info.common_name }}</h4>
          <p class="specs">
            {{ detail.info.reagent }} {{ detail.info.specs }}
            <el-button round>{{ detail.info.prescription }}</el-button>
            <el-button round>{{ detail.info.medicare }}</el-button>
          </p>
          <!-- <p>英文名：{{ detail.info.ename }}</p> -->
          <p>厂家：{{ detail.info.factory }}</p>
        </div>
      </div>

      <div class="top" v-if="bannerArr.length">
        <h4>{{ detail.info.common_name }}</h4>
        <p class="specs">
          {{ detail.info.reagent }} {{ detail.info.specs }}
          <el-button>{{ detail.info.prescription }}</el-button>
          <el-button>{{ detail.info.medicare }}</el-button>
        </p>

        <p>英文名：{{ detail.info.ename }}</p>
        <p>厂家：{{ detail.info.factory }}</p>
      </div>

      <div class="main">
        <section v-if="detail.info.composition">
          <h4>主要成分</h4>
          <p v-html="detail.info.composition"></p>
        </section>
        <section v-if="detail.info.symptoms">
          <h4>适用症</h4>
          <p v-html="detail.info.symptoms"></p>
        </section>
        <section v-if="detail.info.dosage">
          <h4>用法用量</h4>
          <p v-html="detail.info.dosage"></p>
        </section>
        <section v-if="detail.info.side_effects">
          <h4>不良反应</h4>
          <p v-html="detail.info.side_effects"></p>
        </section>
        <section v-if="detail.info.tabu">
          <h4>禁忌</h4>
          <p v-html="detail.info.tabu"></p>
        </section>
        <section v-if="detail.info.attention">
          <h4>注意事项</h4>
          <p v-html="detail.info.attention"></p>
        </section>
        <section v-if="detail.info.effect">
          <h4>药物相互作用</h4>
          <p v-html="detail.info.effect"></p>
        </section>
        <section v-if="detail.info.special_people">
          <h4>特殊人群用药</h4>
          <p v-html="detail.info.special_people"></p>
        </section>
        <section v-if="detail.info.drug_overdose">
          <h4>药物过量</h4>
          <p v-html="detail.info.drug_overdose"></p>
        </section>
        <section v-if="detail.info.toxicology">
          <h4>药理毒理</h4>
          <p v-html="detail.info.toxicology"></p>
        </section>
        <section v-if="detail.info.other">
          <h4>其他</h4>
          <p v-html="detail.info.other"></p>
        </section>
      </div>

      <!-- 点赞收藏图标区 -->
      <div class="icons">
        <div class="favoritesNumber">
          <i
            @click="collectionFn"
            class="iconfont shoucang"
            :class="{ shoucanghover: detail.info.collect_status }"
          ></i>
          <span>{{ detail.info.collect || 0 }}</span>
        </div>
        <div class="like">
          <i
            @click="drugCommentLike"
            class="iconfont zan1"
            :class="{ iconzan2: !detail.info.praise_status }"
          ></i>
          <span>{{ detail.info.praised || 0 }}</span>
        </div>
        <span class="share">分享：</span>
        <share :config="config"></share>
      </div>

      <!-- 评论数 -->
      <commentNum
        :commentNumber="detail.info.comment_num || 0"
        :likeCount="detail.info.likeCount || 0"
        :shareCount="detail.info.share || 0"
        :id="$route.query.id"
        :platform="1"
      ></commentNum>

      <comment
        width="1100"
        @refresh="drugDetail"
        :discover_id="detail.info.id"
        :comments="detail.comment"
        :is_drug="true"
        @delete_comment="delete_comment"
      ></comment>
    </div>
  </div>
</template>


<script>
import comment from "@components/common/comment";
import commentNum from "@components/common/commentNum";

import share from "@components/share/share";

export default {
  components: {
    share,
    comment,
    commentNum,
  },
  data() {
    return {
      config: {
        title: "发现详情",
        url: window.location.href,
        sites: ["wechat", "qq", "weibo"],
        wechatQrcodeTitle: "微信扫一扫：分享",
        wechatQrcodeHelper:
          "<p>扫一扫</p><p>二维码便可将本文分享至朋友圈。</p>",
      },
      detail: "",
      bannerArr: [],
      islod: true,
      loading: true,
    };
  },

  created() {
    if (this.$route.query.type == "first") {
      this.drugsType = "西药";
    } else {
      this.drugsType = "中药";
    }
    this.drugDetail();
  },

  methods: {
    //验证登录
    checkToken() {
      let token = localStorage.getItem("token") || "";
      if (!token) {
        return this.$message({
          message: "请登录后操作",
          type: "error",
        });
      }
    },

    // 获取药品详细信息
    async drugDetail() {
      let data = {
        id: this.$route.query.id,
      };
      let res = await this.$findApi.drugDetail(data);
      this.loading = false;
      if (res.code == 200) {
        this.detail = res.data;
        this.bannerArr = res.data.info.picture;
      } else {
        this.$message.error(res.message);
      }
    },

    // 返回上一层
    goBack1() {
      let num = this.$route.query.num;
      if (!num) {
        this.$router.push("/drugsList?type=" + this.$route.query.type);
      }
    },

    //点赞
    async drugCommentLike() {
      this.checkToken();
      let data = {
        like_id: this.$route.query.id,
        type: 1, //1为药品 2为评论
      };
      if (this.islod) {
        this.islod = false;
        let res = await this.$findApi.drugCommentLike(data);
        this.islod = true;
        if (res.code == 200) {
          if (this.detail.info.praise_status) {
            this.detail.info.praised -= 1;
            this.$message({
              message: "已取消点赞",
              type: "success",
            });
          } else {
            this.detail.info.praised += 1;
            this.$message({
              message: "点赞成功",
              type: "success",
            });
          }
          this.detail.info.praise_status = !this.detail.info.praise_status;
        } else {
          this.$message.error(res.message);
        }
      }
    },
    // 收藏
    async collectionFn() {
      this.checkToken();

      let data = {
        discover_id: this.$route.query.id,
      };
      if (this.islod) {
        this.islod = false;
        let res = await this.$findApi.drugCollection(data);
        this.islod = true;
        if (res.code == 200) {
          if (this.detail.info.collect_status) {
            this.detail.info.collect -= 1;
            this.$message({
              message: "已取消收藏",
              type: "success",
            });
          } else {
            this.detail.info.collect += 1;
            this.$message({
              message: "收藏成功",
              type: "success",
            });
          }
          this.detail.info.collect_status = !this.detail.info.collect_status;
        } else {
          this.$message.error(res.message);
        }
      }
    },
    // 删除评论
    async delete_comment(id) {
      let { message, code } = await this.$findApi.findDelete({
        id,
      });
      if (code == 200) {
        this.detail.comment = this.detail.comment.filter((item) => {
          item.reply = item.reply.filter((it) => {
            return it.id != id;
          });
          return item.id != id;
        });
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      } else {
        this.$message({
          type: "error",
          message,
        });
      }
    },
  },
};
</script>


<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.drugsDetail {
  background: #f2f2f2;
  min-height: 800px;

  // 顶部面包屑
  .top_breadcrumb {
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
    padding: 20px 0;

    span {
      margin-right: 10px;
      color: #717171;
      cursor: pointer;

      &:last-child {
        color: $--themeColor;
      }

      i {
        margin-right: 5px;
        font-weight: 700;
        color: #717171;
      }
    }
  }

  // 轮播图
  /deep/.el-carousel--horizontal {
    width: 1200px;
    margin: 0 auto;
    text-align: center;

    .el-carousel__container {
      .el-image {
        height: 100%;
        width: 100%;
        img {
          object-fit: contain;
        }
      }
    }
  }

  // 内容
  .content {
    width: 1200px;
    max-width: 95%;
    margin: 0 auto;
    background: #fff;
    box-sizing: border-box;
    padding: 30px;
    min-height: 400px;
    margin-bottom: 80px;
    margin-top: 20px;

    .banner {
      width: 100%;
      margin: 0 auto;
      text-align: center;
      position: relative;
      margin-bottom: 20px;

      &::after {
        content: "";
        height: 2px;
        width: 100%;
        background: #d9d9d9;
        position: absolute;
        left: 0;
        bottom: -20px;
      }

      > img {
        width: 100%;
        object-fit: cover;
      }

      > .word {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        color: #fff;
        text-align: left;
        padding: 30px 40px;

        > h4 {
          font-size: 18px;
          margin-bottom: 10px;
        }

        > .specs {
          margin-bottom: 80px;
        }

        button {
          height: 22px;
          padding: 0 15px;
          border-color: #fff;
          background: transparent;
          color: #fff;

          &:first-child {
            margin-left: 10px;
          }
        }
      }
    }

    .top {
      border-bottom: 2px solid #d9d9d9;
      padding: 20px 0;

      h4 {
        font-size: 18px;
        span {
          font-size: 14px;
        }
      }

      .specs {
        color: #8e8e8e;
      }

      p {
        margin-top: 10px;
        font-size: 14px;
        button {
          height: 22px;
          padding: 0 15px;

          border-color: $--themeColor;
          color: $--themeColor;

          &:first-child {
            margin-left: 10px;
          }
        }
      }
    }

    .main {
      padding: 20px 0;
      border-bottom: 2px solid #d9d9d9;

      section {
        width: 100%;
        margin-bottom: 15px;
        h4 {
          color: #333333;
          font-size: 16px;
          margin-bottom: 10px;
        }

        p {
          color: #8e8e8e;
          font-size: 14px;
          line-height: 20px;
          text-align: justify;
        }
      }
    }

    > .icons {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #8f8f8f;
      margin-top: 10px;
      cursor: pointer;
      font-size: 24px;

      i {
        font-size: 24px;
      }

      > .favoritesNumber {
        margin-right: 40px;

        .shoucanghover {
          color: #e60012;
        }
      }

      > .like {
        .zan1 {
          color: #e60012;
        }

        .iconzan2 {
          color: #8f8f8f;
        }
      }

      .report {
        font-size: 22px;
        margin-left: 40px;

        img {
          width: 24px;
          vertical-align: bottom;
        }
      }

      > .share {
        font-size: 16px;
        margin-left: auto;
      }

      > .qq {
        margin: 0 20px;
      }
    }

    > .numberComments {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #333333;
      font-size: 24px;
      margin-top: 30px;
      padding-bottom: 16px;
      box-sizing: border-box;
      border-bottom: 1px solid #f2f2f2;

      > .number {
        font-size: 30px;
        color: #e60012;
        margin-right: 5px;
      }
    }
  }

  @media screen and(max-width:750px) {
    .content {
      margin-top: 0;
      .banner {
        .word {
          box-sizing: border-box;
          padding: 5px 10px;
          font-size: 12px;

          h4 {
            font-size: 12px;
            margin-bottom: 3px;
          }

          button {
            height: 16px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>